
import { defineComponent, PropType } from 'vue';
import { EKSConfig, AWS } from '../types';
import { _EDIT, _VIEW, _CREATE } from '@shell/config/query-params';
import semver from 'semver';
import { Store, mapGetters } from 'vuex';

import { MANAGEMENT } from '@shell/config/types';
import { SETTING } from '@shell/config/settings';
import RadioGroup from '@components/Form/Radio/RadioGroup.vue';
import Banner from '@components/Banner/Banner.vue';

import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import KeyValue from '@shell/components/form/KeyValue.vue';
import Checkbox from '@components/Form/Checkbox/Checkbox.vue';
import LabeledInput from '@components/Form/LabeledInput/LabeledInput.vue';
import eksVersions from '../assets/data/eks-versions';

export default defineComponent({
  name: 'EKSConfig',

  components: {
    LabeledSelect,
    RadioGroup,
    KeyValue,
    Checkbox,
    LabeledInput,
    Banner
  },

  props: {
    mode: {
      type:    String,
      default: _EDIT
    },

    isNewOrUnprovisioned: {
      type:    Boolean,
      default: true
    },

    loadingIam: {
      type:    Boolean,
      default: false
    },

    eksRoles: {
      type:    Array,
      default: () => []
    },

    tags: {
      type:    Object,
      default: () => {}
    },

    kmsKey: {
      type:    String,
      default: ''
    },

    secretsEncryption: {
      type:    Boolean,
      default: false
    },

    serviceRole: {
      type:    String,
      default: ''
    },

    kubernetesVersion: {
      type:    String,
      default: ''
    },

    enableNetworkPolicy: {
      type:    Boolean,
      default: false
    },

    ebsCSIDriver: {
      type:    Boolean,
      default: false
    },

    config: {
      type:     Object as PropType<EKSConfig>,
      required: true
    },

    originalVersion: {
      type:    String,
      default: ''
    }
  },

  data() {
    const store = this.$store as Store<any>;
    // This setting is used by RKE1 AKS GKE and EKS - rke2/k3s have a different mechanism for fetching supported versions
    const supportedVersionRange = store.getters['management/byId'](MANAGEMENT.SETTING, SETTING.UI_SUPPORTED_K8S_VERSIONS)?.value;
    const t = store.getters['i18n/t'];

    return {
      kmsInfo:               {} as {Keys:AWS.KmsKey[]},
      canReadKms:            false,
      supportedVersionRange,
      customServiceRole:     !!this.serviceRole && !!this.serviceRole.length,
      loadingVersions:       false,
      loadingKms:            false,
      allKubernetesVersions: eksVersions as string[],
      serviceRoleOptions:    [{ value: false, label: t('eks.serviceRole.options.standard') }, { value: true, label: t('eks.serviceRole.options.custom') }],

    };
  },

  watch: {
    'config.region': {
      handler() {
        if (this.mode !== _VIEW) {
          this.fetchKubernetesVersions();
          this.fetchKMSKeys();
        }
      },
      immediate: true
    },

    'config.amazonCredentialSecret': {
      handler() {
        if (this.mode !== _VIEW) {
          this.fetchKubernetesVersions();
          this.fetchKMSKeys();
        }
      },
      immediate: true
    },

    'secretsEncryption'(neu) {
      if (!neu) {
        this.$emit('update:kmsKey', '');
      }
    },

    'customServiceRole'(neu) {
      if (!neu) {
        this.$emit('update:serviceRole', '');
      }
    },

    versionOptions: {
      handler(neu) {
        if (neu && neu.length && !this.kubernetesVersion) {
          this.$emit('update:kubernetesVersion', neu[0].value);
        }
      },
      immediate: true
    },

  },

  computed: {
    ...mapGetters({ t: 'i18n/t' }),

    // the control plane k8s version can't be more than one minor version ahead of any node pools
    // verify that all nodepools are on the same version as the control plane before showing upgrade optiopns
    canUpgrade(): boolean {
      if (this.mode === _CREATE) {
        return true;
      }
      const nodeGroups = this.config?.nodeGroups || [];

      const needsUpgrade = nodeGroups.filter((group) => semver.gt(semver.coerce(this.originalVersion), semver.coerce(group.version)) || group._isUpgrading);

      return !needsUpgrade.length;
    },

    hasUpgradesAvailable() {
      return this.versionOptions.filter((opt) => !opt.disabled).length > 1;
    },

    versionOptions(): {value: string, label: string, disabled?:boolean}[] {
      return this.allKubernetesVersions.reduce((versions, v: string) => {
        const coerced = semver.coerce(v);

        if (this.supportedVersionRange && !semver.satisfies(coerced, this.supportedVersionRange)) {
          return versions;
        }
        if (!this.originalVersion) {
          versions.push({ value: v, label: v });
        } else if (semver.lte(semver.coerce(this.originalVersion), coerced)) {
          const withinOneMinor = semver.inc(semver.coerce(this.originalVersion), 'minor');

          if (semver.gt(coerced, withinOneMinor)) {
            versions.push({
              value: v, label: `${ v } ${ this.t('eks.version.upgradeWarning') }`, disabled: true
            });
          } else {
            versions.push({ value: v, label: v });
          }
        }

        return versions;
      }, [] as {value: string, label: string, disabled?:boolean}[]);
    },

    kmsOptions(): string[] {
      return (this.kmsInfo?.Keys || []).map((k) => k.KeyArn);
    }
  },

  methods: {
    // there is no api for fetching eks versions
    // fetch addons and look at which versions they support
    // this assumes that all k8s versions are compatible with at least one addon
    async fetchKubernetesVersions() {
      if (!this.config.region || !this.config.amazonCredentialSecret) {
        return;
      }
      this.loadingVersions = true;
      try {
        const eksClient = await this.$store.dispatch('aws/eks', { region: this.config.region, cloudCredentialId: this.config.amazonCredentialSecret });

        const res = await eksClient.describeAddonVersions({});
        const addons = res?.addons;

        if (!addons) {
          return;
        }
        this.allKubernetesVersions = addons.reduce((versions: string[], addon: AWS.EKSAddon) => {
          (addon?.addonVersions || []).forEach((addonVersion) => {
            (addonVersion?.compatibilities || []).forEach((c) => {
              if (!versions.includes(c.clusterVersion)) {
                versions.push(c.clusterVersion);
              }
            });
          });

          return versions;
        }, []);
      } catch (err) {
        // if the user doesn't have permission to describe addon versions swallow the error and use a fallback list of eks versions
      }

      this.loadingVersions = false;
    },

    async fetchKMSKeys() {
      const { region, amazonCredentialSecret } = this.config;

      if (!region || !amazonCredentialSecret) {
        return;
      }
      this.loadingKms = true;
      const store = this.$store as Store<any>;
      const kmsClient = await store.dispatch('aws/kms', { region, cloudCredentialId: amazonCredentialSecret });

      try {
        this.kmsInfo = await kmsClient.listKeys({});
        this.canReadKms = true;
      } catch (e) {
        this.canReadKms = false;
      }
      this.loadingKms = false;
    },

  }
});

