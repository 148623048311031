import { render, staticRenderFns } from "./IngressTarget.vue?vue&type=template&id=409e93ae&scoped=true&"
import script from "./IngressTarget.vue?vue&type=script&lang=js&"
export * from "./IngressTarget.vue?vue&type=script&lang=js&"
import style0 from "./IngressTarget.vue?vue&type=style&index=0&id=409e93ae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "409e93ae",
  null
  
)

export default component.exports