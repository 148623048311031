
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  props: {
    title: {
      type:    String,
      default: ''
    },

    titleKey: {
      type:    String,
      default: null
    },

    openInitially: {
      type:    Boolean,
      default: false
    }
  },

  data() {
    return { isOpen: this.openInitially };
  },

  computed: { ...mapGetters({ t: 'i18n/t' }) },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    }
  }
});
