
import { PropType, defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { _CREATE, _VIEW } from '@shell/config/query-params';

import Checkbox from '@components/Form/Checkbox/Checkbox.vue';
import LabeledInput from '@components/Form/LabeledInput/LabeledInput.vue';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import UnitInput from '@shell/components/form/UnitInput.vue';
import Taints from '@shell/components/form/Taints.vue';
import ArrayList from '@shell/components/form/ArrayList.vue';
import KeyValue from '@shell/components/form/KeyValue.vue';

import AuthScopes from './AuthScopes.vue';
import { GKEImageTypes } from '../util/gcp';
import type { GKEMachineTypeOption } from '../types/index.d.ts';

export default defineComponent({
  name: 'GKENodePool',

  components: {
    Checkbox,
    LabeledSelect,
    LabeledInput,
    UnitInput,
    Taints,
    ArrayList,
    KeyValue,
    AuthScopes
  },

  props: {
    mode: {
      type:    String,
      default: _CREATE
    },

    isNew: {
      type:    Boolean,
      default: false
    },

    clusterKubernetesVersion: {
      type:    String,
      default: ''
    },

    machineTypeOptions: {
      type:    Array as PropType<GKEMachineTypeOption[]>,
      default: () => []
    },

    serviceAccountOptions: {
      type:    Array as PropType<{label: string, kind?: string, value?: string | null, }[]>,
      default: () => []
    },

    loadingMachineTypes: {
      type:    Boolean,
      default: false
    },

    loadingServiceAccounts: {
      type:    Boolean,
      default: false
    },

    version: {
      type:    String,
      default: ''
    },

    imageType: {
      type:    String,
      default: ''
    },

    machineType: {
      type:    String,
      default: ''
    },

    diskType: {
      type:    String,
      default: ''
    },

    diskSizeGb: {
      type:    Number,
      default: 0
    },

    localSsdCount: {
      type:    Number,
      default: 0
    },

    preemptible: {
      type:    Boolean,
      default: false
    },

    taints: {
      type:    Array,
      default: () => []
    },

    labels: {
      type:    Object,
      default: () => {
        return {};
      }
    },

    tags: {
      type:    Array,
      default: () => []
    },

    name: {
      type:    String,
      default: ''
    },

    initialNodeCount: {
      type:    [String, Number],
      default: ''
    },

    maxPodsConstraint: {
      type:    [String, Number],
      default: ''
    },

    autoscaling: {
      type:    Boolean,
      default: false
    },

    autoRepair: {
      type:    Boolean,
      default: false
    },

    autoUpgrade: {
      type:    Boolean,
      default: false
    },

    minNodeCount: {
      type:    [String, Number],
      default: ''
    },

    maxNodeCount: {
      type:    [String, Number],
      default: ''
    },

    oauthScopes: {
      type:    Array as PropType<string[]>,
      default: () => []
    },

    serviceAccount: {
      type:    String,
      default: null
    },

    rules: {
      type:    Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return { upgradeKubernetesVersion: false, initialVersion: this.version };
  },

  watch: {
    upgradeKubernetesVersion(neu) {
      if (neu) {
        this.$emit('update:version', this.clusterKubernetesVersion);
      } else {
        this.$emit('update:version', this.initialVersion);
      }
    },

    clusterKubernetesVersion: {
      handler(neu: string) {
        if (neu && this.mode === _CREATE) {
          this.$emit('update:version', neu);
        }
      },
      immediate: true
    },

    autoscaling(neu: boolean) {
      if (!neu) {
        this.$emit('update:minNodeCount', null);
        this.$emit('update:maxNodeCount', null);
      } else {
        this.$emit('update:minNodeCount', '1');
        this.$emit('update:maxNodeCount', '3');
      }
    }
  },

  computed: {
    ...mapGetters({ t: 'i18n/withFallback' }),

    _VIEW() {
      return _VIEW;
    },

    // when initially created nodepools are configured to use the same k8s version as the control plane (clusterKubernetesVersion)
    // on edit, if the cp version is updated, the user is given the option to update each node pool as well
    upgradeAvailable() {
      if (this.mode === _CREATE) {
        return false;
      }

      return this.initialVersion !== this.clusterKubernetesVersion;
    },

    imageTypeOptions() {
      return (GKEImageTypes || []).map((type) => {
        return {
          value: type,
          label: this.t(`gke.imageType.${ type }`, null, type)
        };
      });
    },

    diskTypeOptions() {
      return [
        {
          label: this.t('gke.diskType.standard'),
          value: 'pd-standard',
        },
        {
          label: this.t('gke.diskType.ssd'),
          value: 'pd-ssd',
        }
      ];
    },

    selectedImageType: {
      get() {
        return this.imageTypeOptions.find((opt) => opt.value === this.imageType) || { value: this.imageType, label: this.t(`gke.imageType.${ this.imageType }`, null, this.imageType) };
      },
      set(neu: {label: string, kind?: string, value?: string, disabled?: boolean}) {
        this.$emit('update:imageType', neu.value);
      }
    },

    selectedMachineType: {
      get(): GKEMachineTypeOption | undefined {
        return this.machineTypeOptions.find((opt) => opt?.value === this.machineType);
      },
      set(neu: GKEMachineTypeOption) {
        this.$emit('update:machineType', neu.value);
      }
    },

    selectedDiskType: {
      get() {
        return this.diskTypeOptions.find((opt) => opt.value === this.diskType);
      },
      set(neu: {label:string, value: string}) {
        this.$emit('update:diskType', neu.value);
      }
    },

    selectedServiceAccount: {
      get() {
        return this.serviceAccountOptions.find((opt) => opt.value === this.serviceAccount) || { label: this.serviceAccount, value: this.serviceAccount };
      },
      set(neu: {label: string, value: string}) {
        this.$emit('update:serviceAccount', neu.value);
      }
    }
  },
});

