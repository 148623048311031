
import { defineComponent } from 'vue';
import { _CREATE, _VIEW } from '@shell/config/query-params';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import SelectCredential from '@shell/edit/provisioning.cattle.io.cluster/SelectCredential.vue';
import { DEFAULT_REGION } from './CruEKS.vue';
import { mapGetters } from 'vuex';
import { AWS } from 'types';

export default defineComponent({
  name: 'EKSAccountAccess',

  components: {
    LabeledSelect,
    SelectCredential
  },

  props: {
    mode: {
      type:    String,
      default: _CREATE
    },

    credential: {
      type:    String,
      default: null
    },

    region: {
      type:    String,
      default: ''
    }
  },

  async fetch() {
    if (this.mode !== _VIEW) {
      this.defaultRegions = await this.$store.dispatch('aws/defaultRegions');
      if (this.defaultRegions.length && !this.region) {
        this.$emit('update-region', DEFAULT_REGION);
      }
    }
  },

  data() {
    return { regions: [] as string[], defaultRegions: [] as string[] };
  },

  watch: {
    isAuthenticated: {
      async handler(neu) {
        if (neu && this.mode !== _VIEW) {
          await this.fetchRegions();
        } else {
          if (this.defaultRegions.length && !this.defaultRegions.includes(this.region)) {
            if (this.defaultRegions.includes(DEFAULT_REGION)) {
              this.$emit('update-region', DEFAULT_REGION);
            } else {
              this.$emit('update-region', this.defaultRegions[0]);
            }
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    async fetchRegions() {
      const { region, credential }: { region: string, credential: string} = this;

      if (!!region && !!credential) {
        try {
          const ec2Client = await this.$store.dispatch('aws/ec2', { region, cloudCredentialId: credential });

          const res: {Regions: AWS.EC2Region[]} = await ec2Client.describeRegions({});

          this.regions = (res?.Regions || []).map((r) => r.RegionName);
        } catch (err) {
          this.$emit('error', this.t('eks.errors.fetchingRegions', { err }));
        }
      }
    },
  },

  computed: {
    ...mapGetters({ t: 'i18n/t' }),

    // once the credential is validated we can fetch a list of available regions
    isAuthenticated(): boolean {
      return !!this.credential;
    },

    regionOptions(): string[] {
      return this.regions.length ? this.regions : this.defaultRegions;
    },

    CREATE(): string {
      return _CREATE;
    },

    VIEW(): string {
      return _VIEW;
    },

  },
});
