
import { _CREATE, _EDIT, _VIEW } from '@shell/config/query-params';
import { PropType, defineComponent } from 'vue';
import { Store, mapGetters } from 'vuex';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import Checkbox from '@components/Form/Checkbox/Checkbox.vue';
import ArrayList from '@shell/components/form/ArrayList.vue';
import Banner from '@components/Banner/Banner.vue';

import RadioGroup from '@components/Form/Radio/RadioGroup.vue';

import { AWS } from '../types';

export default defineComponent({
  name: 'EKSNetworking',

  components: {
    LabeledSelect,
    ArrayList,
    Checkbox,
    RadioGroup,
    Banner
  },

  props: {
    mode: {
      type:    String,
      default: _EDIT
    },

    region: {
      type:    String,
      default: ''
    },

    amazonCredentialSecret: {
      type:    String,
      default: ''
    },

    subnets: {
      type:    Array as PropType<string[]>,
      default: () => []
    },

    securityGroups: {
      type:    Array as PropType<string[]>,
      default: () => []
    },

    publicAccess: {
      type:    Boolean,
      default: false
    },

    privateAccess: {
      type:    Boolean,
      default: false
    },

    publicAccessSources: {
      type:    Array,
      default: () => []
    },

    statusSubnets: {
      type:    Array as PropType<string[]>,
      default: () => []
    },

    rules: {
      type:    Object,
      default: () => {}
    }
  },

  watch: {
    amazonCredentialSecret: {
      handler(neu) {
        if (neu && !this.isView) {
          this.fetchVpcs();
          this.fetchSecurityGroups();
        }
      },
      immediate: true
    },
    region: {
      handler(neu ) {
        if (neu && !this.isView) {
          this.fetchVpcs();
          this.fetchSecurityGroups();
        }
      },
      immediate: true
    },

    'chooseSubnet'(neu: boolean) {
      if (!neu) {
        this.$emit('update:subnets', []);
      }
    },

    selectedVpc(neu: string, old: string) {
      if (!!old) {
        this.$emit('update:securityGroups', []);
      }
    }

  },

  data() {
    return {
      loadingVpcs:           false,
      loadingSecurityGroups: false,
      vpcInfo:               {} as {Vpcs: AWS.VPC[]},
      subnetInfo:            {} as {Subnets: AWS.Subnet[]},
      securityGroupInfo:     {} as {SecurityGroups: AWS.SecurityGroup[]},
      chooseSubnet:          !!this.subnets && !!this.subnets.length
    };
  },

  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    // map subnets to VPCs
    // {[vpc id]: [subnets]}
    vpcOptions() {
      const out: {key:string, label:string, _isSubnet?:boolean, kind?:string}[] = [];
      const vpcs: AWS.VPC[] = this.vpcInfo?.Vpcs || [];
      const subnets: AWS.Subnet[] = this.subnetInfo?.Subnets || [];
      const mappedSubnets: {[key:string]: AWS.Subnet[]} = {};

      subnets.forEach((s) => {
        if (!mappedSubnets[s.VpcId]) {
          mappedSubnets[s.VpcId] = [s];
        } else {
          mappedSubnets[s.VpcId].push(s);
        }
      });
      vpcs.forEach((v) => {
        const { VpcId = '', Tags = [] } = v;
        const nameTag = Tags.find((t) => {
          return t.Key === 'Name';
        })?.Value;

        const formOption = {
          key: VpcId, label: `${ nameTag } (${ VpcId })`, kind: 'group'
        };

        out.push(formOption);
        if (mappedSubnets[VpcId]) {
          mappedSubnets[VpcId].forEach((s) => {
            const { SubnetId, Tags = [] } = s;
            const nameTag = Tags.find((t) => {
              return t.Key === 'Name';
            })?.Value;

            const subnetFormOption = {
              key:       SubnetId,
              label:     `${ nameTag } (${ SubnetId })`,
              _isSubnet: true,
              disabled:  !!this.selectedVpc && VpcId !== this.selectedVpc
            };

            out.push(subnetFormOption);
          });
        }
      });

      return out;
    },

    securityGroupOptions() {
      const allGroups = this.securityGroupInfo?.SecurityGroups || [];

      return allGroups.reduce((opts, sg) => {
        if (sg.VpcId !== this.selectedVpc) {
          return opts;
        }
        opts.push({
          label: `${ sg.GroupName } (${ sg.GroupId })`,
          value: sg.GroupId
        });

        return opts;
      }, [] as {label: string, value: string}[]);
    },

    displaySubnets: {
      get(): {key:string, label:string, _isSubnet?:boolean, kind?:string}[] | string[] {
        const subnets: string[] = this.chooseSubnet ? this.subnets : this.statusSubnets;

        // vpcOptions will be empty in 'view config' mode, where aws API requests are not made
        return this.vpcOptions.length ? this.vpcOptions.filter((option) => subnets.includes(option.key)) : subnets;
      },
      set(neu: {key:string, label:string, _isSubnet?:boolean, kind?:string}[]) {
        this.$emit('update:subnets', neu.map((s) => s.key));
      }
    },

    selectedVpc() {
      if (!this.chooseSubnet) {
        return null;
      }

      return (this.subnetInfo?.Subnets || []).find((s) => this.subnets.includes(s.SubnetId))?.VpcId;
    },

    isNew(): boolean {
      return this.mode === _CREATE;
    },

    isView():boolean {
      return this.mode === _VIEW;
    }
  },

  methods: {
    async fetchVpcs() {
      this.loadingVpcs = true;
      const { region, amazonCredentialSecret } = this;

      if (!region || !amazonCredentialSecret) {
        return;
      }
      const store: Store<any> = this.$store;
      const ec2Client = await store.dispatch('aws/ec2', { region, cloudCredentialId: amazonCredentialSecret });

      try {
        this.vpcInfo = await ec2Client.describeVpcs({ });
        this.subnetInfo = await ec2Client.describeSubnets({ });
      } catch (err) {
        this.$emit('error', err);
      }
      this.loadingVpcs = false;
    },

    async fetchSecurityGroups() {
      this.loadingSecurityGroups = true;
      const { region, amazonCredentialSecret } = this;

      if (!region || !amazonCredentialSecret) {
        return;
      }
      const store: Store<any> = this.$store;
      const ec2Client = await store.dispatch('aws/ec2', { region, cloudCredentialId: amazonCredentialSecret });

      try {
        this.securityGroupInfo = await ec2Client.describeSecurityGroups({ });
      } catch (err) {
        this.$emit('error', err);
      }
      this.loadingSecurityGroups = false;
    }
  }
});
