
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { _CREATE } from '@shell/config/query-params';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import Checkbox from '@components/Form/Checkbox/Checkbox.vue';
import Banner from '@components/Banner/Banner.vue';

const NONE_OPTION = 'none';

const MONITORING_OPTION = 'monitoring.googleapis.com/kubernetes';

const LOGGING_OPTION = 'logging.googleapis.com/kubernetes';

export default defineComponent({
  name: 'GKEAdvancedOptions',

  components: {
    LabeledSelect,
    Checkbox,
    Banner,
  },

  props: {
    mode: {
      type:    String,
      default: _CREATE
    },

    isNewOrUnprovisioned: {
      type:    Boolean,
      default: true
    },

    loggingService: {
      type:    String,
      default: NONE_OPTION
    },

    monitoringService: {
      type:    String,
      default: NONE_OPTION
    },

    maintenanceWindow: {
      type:    String,
      default: ''
    },

    httpLoadBalancing: {
      type:    Boolean,
      default: false
    },

    horizontalPodAutoscaling: {
      type:    Boolean,
      default: false
    },

    enableKubernetesAlpha: {
      type:    Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({ t: 'i18n/t' }),

    loggingEnabled: {
      get() {
        return !!this.loggingService && this.loggingService !== NONE_OPTION;
      },
      set(neu: boolean) {
        if (neu) {
          this.$emit('update:loggingService', LOGGING_OPTION);
        } else {
          this.$emit('update:loggingService', NONE_OPTION);
        }
      }
    },

    monitoringEnabled: {
      get() {
        return !!this.monitoringService && this.monitoringService !== NONE_OPTION;
      },
      set(neu: boolean) {
        if (neu) {
          this.$emit('update:monitoringService', MONITORING_OPTION);
        } else {
          this.$emit('update:monitoringService', NONE_OPTION);
        }
      }
    },

    maintenanceWindowOpts() {
      return [
        {
          value: '',
          label: this.t('gke.maintenanceWindow.any'),
        },
        {
          value: '00:00',
          label: '12:00AM',
        },
        {
          value: '03:00',
          label: '3:00AM',
        },
        {
          value: '06:00',
          label: '6:00AM',
        },
        {
          value: '09:00',
          label: '9:00AM',
        },
        {
          value: '12:00',
          label: '12:00PM',
        },
        {
          value: '15:00',
          label: '3:00PM',
        },
        {
          value: '19:00',
          label: '7:00PM',
        },
        {
          value: '21:00',
          label: '9:00PM',
        },
      ];
    },

    /**
     * Unclear why this is true on edit, but not create. It may be a bug: consequently, a banner is shown instead of using form validation to make this a strict requirement
     * See https://github.com/rancher/rancher/issues/32148#issuecomment-820010852
     */
    showLoggingMonitoringBanner() {
      if (this.mode === _CREATE) {
        return false;
      }

      return this.monitoringEnabled !== this.loggingEnabled;
    }
  },

});

