
import { PropType, defineComponent } from 'vue';

interface Badge {
  stateBackground: string;
  stateDisplay: string;
}

/**
 * Badge state component.
 * <p>Represents a badge whose label and color is either taken from the value property or
 * from the label and color properties. The state property takes precedence.</p>
 */
export default defineComponent({
  props: {
    /**
     * A value having the properties `stateBackground` and `stateDisplay`
     */
    value: {
      type:    Object as PropType<Badge>,
      default: null
    },

    /**
     * Badge color. `stateBackground` of the value property takes precedence if supplied
     */
    color: {
      type:    String,
      default: null
    },

    /**
     * Optional icon to be shown before the label
     */
    icon: {
      type:    String,
      default: null
    },

    /**
     * Label to display in the badge. `stateDisplay` of the value property takes precedence if supplied
     */
    label: {
      type:    String,
      default: null
    }
  },

  computed: {
    bg(): string | null {
      return this.value?.stateBackground || this.color;
    },

    msg(): string | null {
      return this.value?.stateDisplay || this.label;
    }
  }
});
