
import { defineComponent } from 'vue';
import { Banner } from '@components/Banner';

export default defineComponent({
  name: 'AuthProviderWarningBanners',

  components: { Banner },

  props: {
    tArgs: {
      type:     Object,
      required: true,
      default:  () => { },
    }
  }
});
