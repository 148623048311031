
import { defineComponent } from 'vue';
import { _CREATE, _VIEW } from '@shell/config/query-params';
import LabeledInput from '@components/Form/LabeledInput/LabeledInput.vue';
import SelectCredential from '@shell/edit/provisioning.cattle.io.cluster/SelectCredential.vue';
import AsyncButton from '@shell/components/AsyncButton.vue';
import { mapGetters, Store } from 'vuex';
import { getGKEZones } from '../util/gcp';

export default defineComponent({
  name: 'GKEAccountAccess',

  components: {
    LabeledInput,
    SelectCredential,
    AsyncButton
  },

  props: {
    mode: {
      type:    String,
      default: _CREATE
    },

    credential: {
      type:    String,
      default: null
    },

    project: {
      type:    String,
      default: ''
    },

    isAuthenticated: {
      type:    Boolean,
      default: false
    },
  },

  created() {
    if (this.mode === _VIEW) {
      this.$emit('update:isAuthenticated', true);
    }
  },

  computed: {
    ...mapGetters({ t: 'i18n/t' }),

    CREATE(): string {
      return _CREATE;
    },

    VIEW(): string {
      return _VIEW;
    },

    isView():boolean {
      return this.mode === _VIEW;
    }

  },

  methods: {
    async testProjectId(cb: (success: Boolean)=>{}) {
      const store = this.$store as Store<any>;

      try {
        await getGKEZones(store, this.credential, this.project, {});

        this.$emit('update:isAuthenticated', true);

        // eslint-disable-next-line standard/no-callback-literal, node/no-callback-literal
        return cb(true);
      } catch (e) {
        this.$emit('error', e?.data || e);
        this.$emit('update:isAuthenticated', false);

        // eslint-disable-next-line standard/no-callback-literal, node/no-callback-literal
        return cb(false);
      }
    },

    // gcp credentials include a project id - we can grab that and auto-fill to save users having to manually enter it
    // this only applies to new credentials because of the way credential data is stored
    parseNewCredential(e) {
      const authJson = e?.googlecredentialConfig?.authEncodedJson;

      if (authJson) {
        try {
          // eslint-disable-next-line camelcase
          const { project_id:projectId } = JSON.parse(authJson);

          if (projectId) {
            this.$emit('update:project', projectId);
          }
        } catch (e) {

        }
      }
    }
  },
});
