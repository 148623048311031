
import { _CREATE } from '@shell/config/query-params';
import { PropType, defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import RadioGroup from '@components/Form/Radio/RadioGroup.vue';
import isEqual from 'lodash/isEqual';

import {
  addGKEAuthScope, GKEOauthScopeFormOptions, GKEOauthScopeOptions, googleFullAuthUrl, getGoogleAuthDefaultURLs, getValueFromGKEOauthScopes,
} from '../util/oauth';

export default defineComponent({
  name: 'GKEAuthScopes',

  components: {
    RadioGroup,
    LabeledSelect
  },

  props: {
    mode: {
      type:    String,
      default: _CREATE
    },

    disabled: {
      type:    Boolean,
      default: false
    },

    value: {
      type:    Array as PropType<string[]>,
      default: () => []
    }
  },

  created() {
    if (this.mode !== _CREATE) {
      if (this.value && this.value.length === 1 && this.value[0] === googleFullAuthUrl) {
        this.$set(this, 'scopeMode', GKEOauthScopeOptions.FULL);
      } else if (isEqual(this.value, getGoogleAuthDefaultURLs())) {
        this.$set(this, 'scopeMode', GKEOauthScopeOptions.DEFAULT);
      }
    }
  },

  data() {
    const t = this.$store.getters['i18n/t'];

    return {
      formOptions:      GKEOauthScopeFormOptions,
      scopeModeOptions: Object.values(GKEOauthScopeOptions).map((opt) => {
        return {
          label: t(`gke.authScopes.modes.${ opt }`),
          value: opt
        };
      }),
      scopeMode: GKEOauthScopeOptions.DEFAULT
    };
  },

  watch: {
    scopeMode(neu) {
      switch (neu) {
      case GKEOauthScopeOptions.DEFAULT:
        this.$emit('input', getGoogleAuthDefaultURLs());
        break;
      case GKEOauthScopeOptions.FULL:
        this.$emit('input', [googleFullAuthUrl]);
        break;
      default:
        this.$emit('input', []);
      }
    }
  },

  computed: { ...mapGetters({ t: 'i18n/t' }) },

  methods: {
    getScopeValue(scopeKey:keyof typeof GKEOauthScopeFormOptions): string {
      return getValueFromGKEOauthScopes(this.value, scopeKey);
    },

    setScopeValue(scopeKey: keyof typeof GKEOauthScopeFormOptions, neu: string) {
      const newScopes = addGKEAuthScope(this.value, scopeKey, neu);

      this.$emit('input', newScopes);
    },

    nextScopeKey(index: number) {
      if (index <= Object.keys(this.formOptions).length - 2) {
        return Object.keys(this.formOptions)[index + 1];
      }
    }
  }
});
